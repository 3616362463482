import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedirectGuard } from 'nfrp-shared-angular';
import { UnsavedChangesGuard } from 'nfrp-shared-angular';
import { PageNotFoundComponent } from './page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./containers/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: 'externalRedirect/:url',
    component: PageNotFoundComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [RedirectGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
